<template>
  <div class="task_hall_container">
    <div class="task_hall_title">
      <img class="task_hall_logo" src="@/assets/images/task-hall-logo.png"/>
      <div>任务大厅</div>
    </div>
    <div class="task_hall_select">
      <div class="task_hall_item">
        <div class="item_title">任务级别:</div>
        <div class="items_container">
          <div
            class="select_item"
            :class="{'active_item': index === activeLevelIndex}"
            v-for="(item, index) in taskLevelItems"
            :key="index"
            @click="selectLevelClick(index, item.value)"
          >{{item.name}}</div>
        </div>
      </div>
      <div class="task_hall_item">
        <div class="item_title">任务时限:</div>
        <div class="items_container">
          <div
            class="select_item"
            :class="{'active_item': index === activeTimeIndex}"
            v-for="(item, index) in taskTimeItems"
            :key="index"
            @click="selectTimeClick(index, item.value)"
          >{{item.name}}</div>
        </div>
      </div>
      <div class="task_hall_item">
        <div class="item_title">任务奖励:</div>
        <div class="items_container">
          <div
            class="select_item"
            :class="{'active_item': index === activeAwardsIndex}"
            v-for="(item, index) in taskAwardsItems"
            :key="index"
            @click="selectAwardsClick(index, item)"
          >{{item.name}}</div>
        </div>
      </div>
    </div>

    <!-- 任务大厅表格 -->
    <el-table
      class="table_border"
      :data="tableList"
      :cell-style="cellStyle"
      @row-click="handleToDetail"
      @sort-change="sortChange"
      v-loading="isLoading">
      <el-table-column
        width="80"
        label="任务编号"
        :index="indexMethod"
        type="index">
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        label="任务名称"
        prop="title">
      </el-table-column>
      <el-table-column
        sortable="custom"
        label="任务奖励"
        prop="amount">
      </el-table-column>
      <el-table-column
        label="任务级别">
        <template slot-scope="scope">
          <el-tag
            :type="{
              [TASK_LEVEL.COMMON]: 'success',
              [TASK_LEVEL.GENERAL]: 'info',
              [TASK_LEVEL.COMPLEX]: 'warning',
              [TASK_LEVEL.DIFFICULTY]: 'danger'
            }[scope.row.level]">
            {{
              {
                [TASK_LEVEL.COMMON]: '普通',
                [TASK_LEVEL.GENERAL]: '一般',
                [TASK_LEVEL.COMPLEX]: '复杂',
                [TASK_LEVEL.DIFFICULTY]: '困难'
              }[scope.row.level]
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="任务状态">
        <template slot-scope="scope">
          <span>
            {{
              {
                [TASK_STATUS.PUBLISHED]: '已发布',
                [TASK_STATUS.ONGOING]: '进行中',
                [TASK_STATUS.SUCCESSFUL]: '已完成',
                [TASK_STATUS.AUDIT]: '待审核',
                [TASK_STATUS.REJECT]: '已驳回',
                [TASK_STATUS.CANCELED]: '已取消',
                [TASK_STATUS.WAITING_FOR_COMPANY]: '等待企业确认',
                [TASK_STATUS.WAITING_FOR_REWARDS]: '等待奖励发放',
              }[scope.row.status]
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        label="任务时限"
        prop="timeLimit">
        <template slot-scope="scope">
          <span>{{scope.row.timeLimit}}天</span>
        </template>
      </el-table-column>
      <el-table-column
        width="160"
        show-overflow-tooltip
        label="任务截止时间"
        prop="deadline">
      </el-table-column>
      <el-table-column
        width="160"
        show-overflow-tooltip
        label="任务发布时间"
        prop="publishedAt">
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <pagination
      :pageInfo="pageInfo"
      @paginationChange="handleCurrentChange">
    </pagination>
  </div>
</template>

<script>
import api from '@/api/index.js'
import Pagination from '@/components/pagination/index.vue'
import globalVar from '@/configs/globalVar'

export default {
  name: 'UserTaskHall',
  components: {
    Pagination
  },
  data () {
    return {
      tableList: [],
      isLoading: false,
      activeLevelIndex: 0,
      activeTimeIndex: 0,
      activeAwardsIndex: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 多条件查询任务列表
      getListInfo: {},
      taskLevelItems: [
        { value: '0, 1, 2, 3', name: '不限' },
        { value: 0, name: '普通' },
        { value: 1, name: '一般' },
        { value: 2, name: '复杂' },
        { value: 3, name: '困难' }
      ],
      taskTimeItems: [
        { value: '', name: '不限' },
        { value: 1, name: '1天' },
        { value: 3, name: '3天' },
        { value: 5, name: '5天' },
        { value: 10, name: '10天' },
        { value: 30, name: '一个月' }
      ],
      taskAwardsItems: [
        { amountMin: '', amountMax: '', name: '不限' },
        { amountMin: 0, amountMax: 99, name: '0-99' },
        { amountMin: 100, amountMax: 199, name: '100-199' },
        { amountMin: 200, amountMax: 499, name: '200-499' },
        { amountMin: 500, amountMax: 999, name: '500-999' },
        { amountMin: 1000, amountMax: '', name: '1000以上' }
      ],
      // 排序关键字
      sortWord: 'created_at:desc'
    }
  },
  computed: {
    TASK_LEVEL () {
      return globalVar.TASK_LEVEL
    },
    TASK_STATUS () {
      return globalVar.TASK_STATUS
    }
  },
  created () {
    this.getTaskList()
  },
  methods: {
    cellStyle () {
      return 'height: 48px; padding: 0px;'
    },
    // 选择任务等级
    selectLevelClick (index, value) {
      if (this.activeLevelIndex === index) return
      this.activeLevelIndex = index
      this.getListInfo.levels = value
      this.getTaskList()
    },
    indexMethod (index) {
      // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    },
    // 选择任务时长
    selectTimeClick (index, value) {
      if (this.activeTimeIndex === index) return
      this.activeTimeIndex = index
      this.getListInfo.timeLimit = value
      this.getTaskList()
    },
    // 选择任务奖励
    selectAwardsClick (index, { amountMin, amountMax }) {
      if (this.activeAwardsIndex === index) return
      this.activeAwardsIndex = index
      this.getListInfo.amountMin = amountMin
      this.getListInfo.amountMax = amountMax
      this.getTaskList()
    },
    // 前往详情页面
    handleToDetail (row) {
      this.$router.push({
        path: `/user/task-detail/${row.id}`
      })
    },
    handleCurrentChange (newNum) {
      this.pageInfo.pageNum = newNum
      this.getTaskList()
    },
    getTaskList () {
      this.isLoading = true
      api.taskHallList({
        ...this.getListInfo,
        ...this.pageInfo,
        words: this.sortWord
      }).then(res => {
        if (res.data.code === 0) {
          const { total, list, currentPage } = res.data.data
          this.pageInfo.total = total
          this.tableList = list
          this.pageInfo.pageNum = currentPage
        } else {
          this.$message.error('请求数据失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      }).finally(() => {
        this.isLoading = false
      })
    },
    // 列表排序
    sortChange (column) {
      if (column.prop === 'amount') {
        if (column.order === 'ascending') {
          this.sortWord = 'amount:asc'
        } else if (column.order === 'descending') {
          this.sortWord = 'amount:desc'
        } else {
          this.sortWord = 'created_at:desc'
        }
        this.getTaskList()
      }
      if (column.prop === 'timeLimit') {
        if (column.order === 'ascending') {
          this.sortWord = 'time_limit:asc'
        } else if (column.order === 'descending') {
          this.sortWord = 'time_limit:desc'
        } else {
          this.sortWord = 'created_at:desc'
        }
        this.getTaskList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.task_hall_container {
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  padding: 0 30px;
  overflow: auto;
  .task_hall_title {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    .task_hall_logo {
      margin-right: 10px;
    }
  }
  .task_hall_select {
    width: 100%;
    height: 150px;
    padding: 0 30px;
    box-sizing: border-box;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .task_hall_item {
      height: 50px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px dashed #DCDFE6;
      .item_title {
        font-size: 14px;
        font-weight: bold;
        color: #909399;
        margin-right: 50px;
      }
      .items_container {
        display: flex;
        .select_item {
          margin-right: 30px;
          color: #303133;
          font-size: 14px;
          cursor: pointer;
          padding: 4px 10px;
        }
        .active_item {
          background-color: #3473E6;
          color: #fff;
          border-radius: 2px;
        }
      }
    }
    .task_hall_item:last-child {
      border-bottom: none;
    }
  }
  .table_border {
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }
}
</style>
